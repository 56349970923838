<template>
  <div>
    <article>
      <h3 class="center">{{appName}}第三方信息共享清单</h3>
      <section>
        <p>
          为保障{{appName}}的稳定运行以及实现包括但不限于第三方账号登录、内容审核、运营统计等的相关功能，我们会接入由合作方提供的软件开发包（SDK）、应用程序接口（API）、应用程序插件等代码或通过其他合作方式，以实现前述目的。我们会对获取信息的合作方的代码进行严格的安全监测，以保护数据安全。请注意，合作方的代码可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。
        </p>
      </section>
      <section>
        <div class="bold">为使您更清晰的了解我们有关个人信息共享情况，制定下述清单</div>
      </section>
      <section>
        <div class="bold">一、合作方第三方信息共享清单</div>
      </section>
      <section>
        <ul>
          <li v-for="item in tableData" style="margin: 12px auto">
            <div>
              <span class="bold">数据接受方:</span> <span>{{ item['Receive'] }}</span>
            </div>
            <div>
              <span class="bold">联系方式:</span> <span>{{ item['Contact'] }}</span>
            </div>
            <div>
              <span class="bold">处理方式:</span> <span>{{ item['Treatment'] }}</span>
            </div>
            <div>
              <span class="bold">用途:</span> <span>{{ item['Uses'] }}</span>
            </div>
            <div>
              <span class="bold">共享个人信息类型:</span>
              <span>{{ item['Type'] }}</span>
            </div>
          </li>
        </ul>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'UserReleaseCommitment',
    components: {},
    props: {},
    data() {
      return {
        appName: '',
        tableData: [
          {
            Receive: '数美天下（北京）科技有限公司',
            Contact: '400-850-0030',
            Treatment: '委托处理',
            Uses: '落实合规要求，监测识别算法输入和输出数据中的违法违规和不良信息（内容审核）',
            Type: '用户内容数据（即使用生成合成算法/功能的输入数据、输出数据）',
          },
          {
            Receive: '网易（杭州）网络有限公司',
            Contact: '95163223',
            Treatment: '委托处理',
            Uses: '落实合规要求，监测识别算法输入和输出数据中的违法违规和不良信息（内容审核）',
            Type: '用户内容数据（即使用生成合成算法/功能的输入数据、输出数据）',
          },
          {
            Receive: '北京时域科技有限公司',
            Contact: 'ace@timedomain.ai',
            Treatment: '委托处理',
            Uses: '合成演唱歌曲',
            Type: '生成合成算法/功能生成的旋律、歌词数据（不含用户输入数据）',
          },
        ],
        option1: {
          column: [
            {
              label: '数据接收方',
              prop: 'Receive',
            },
            {
              label: '联系方式',
              prop: 'Contact',
            },
            {
              label: '处理方式',
              prop: 'Treatment',
            },
            {
              label: '用途',
              prop: 'Uses',
            },
            {
              label: '共享个人信息类型',
              prop: 'Type',
            },
          ],
        },
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      if (this.$route.query.type && +this.$route.query.type == 2) {
        this.appName = '快乐跟唱';
      } else {
        this.appName = '快音AI写歌';
      }
      document.title = `${this.appName}第三方信息共享清单`;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }
  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
    text-indent: 2em;
  }
  div.bold {
    margin: 12px 0;
    font-size: 13px;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  .align-start {
    width: 100%;
    overflow-x: scroll;
    text-align: start;
  }
  .align-start a {
    color: #007aff;
  }
  .bold {
    font-weight: bold;
  }
  ::-webkit-scrollbar {
    display: none;
  }
</style>
